import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import Rodal from 'rodal'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Helpers from '../../services/helpers.jsx'

class CollectionView extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
			collection: null,
			playlistsList: [],
			breadcrumb: {title: 'Back to collections', path: '/assets/collections'},
			playlists: {
				columns: [{
					label: 'Name',
					key: 'name'
				}, {
					label: 'Tracks',
					key: 'assets',
					view: field => {
						if(!field)
							return '-'
						return field.length
					}
				}, {
					label: 'Duration',
					key: 'duration',
					view: function(field) {
						if(!field)
							return '-'

						return Helpers.formatTime(field)
					}
				}, {
					label: 'Vox tag',
					key: 'tag'
				}, {
					label: 'Type',
					key: 'type',
					view: field => {
						if(field === 'vox')
							return 'VOX Playlist'
						else if(field === 'custom')
							return 'Tailor-made'
						else if(field === 'sr_library')
							return 'SR Library'
						else
							return '-'
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want remove this playlist from the collection?");
						if (r === false) return
						let collection = JSON.parse(JSON.stringify(this.state.collection))
						if(collection.playlists) {
							collection.playlists = collection.playlists.map(asset => asset.id)
							collection.playlists.splice(key, 1)
						}
						// return console.log(collection)
						this.setState({ loading: true })
						API.update('collections', this.state.collection.id, collection).then(collection => {
							this.setState({ ...this.state, loading: false, collection: collection, playlists: { ...this.state.playlists, entities: collection.playlists } })
						}).catch((err) => {
							console.log(err)
							this.setState({ loading: false })
							alert('An unexpected error has occured')
						})
					}
				}],
				entities: [],
				filters: [],
				actions: [{
					classList: 'blue save',
					label: 'Add playlist',
					onClick: () => {
						this.setState({
							showPopup: true
						})
					}
				}],
				selectedItems: [],
				multiSelect: false
			},
			showPopup: false,
			selectedPlaylist: null
		}
	}

	componentDidMount() {
		API.getCollection(this.props.match.params.collectionId).then(collection => {
			API.getPlaylists().then(playlists => {
				this.setState({ ...this.state, collection: collection, playlistsList: playlists, playlists: { ...this.state.playlists, entities: collection.playlists }, loading: false })
			})
		})
	}

	onChangeFile(event) {
		event.stopPropagation();
		event.preventDefault();

		// Check if there are files to upload
		if(event.target.files.length < 1)
			return

		// Display loader
		this.setState({ loading: true })

		// Upload assets
		let uploadedAssets = []
		let uploads = []
		for (let i = 0; i < event.target.files.length; i++) {
			uploads.push(API.postAsset(event.target.files[i]).then(file => uploadedAssets.push(file.id)))
		}

		Promise.all(uploads).then((asset) => {
			let playlist = { ...this.state.playlist }
			if(playlist.assets) {
				playlist.assets = playlist.assets.map(asset => asset.id)
				playlist.assets = playlist.assets.concat(uploadedAssets)
			} else {
				playlist.assets = uploadedAssets
			}
			API.update('playlists', this.state.playlist.id, playlist).then(playlist => {
				API.getPlaylist(this.props.match.params.playlistId).then(playlist => this.setState({ ...this.state, loading: false, selectedFile: null, playlist: playlist, assets: { ...this.state.assets, entities: playlist.assets } }))
			}).catch((err) => {
				this.setState({ loading: false, selectedFile: null })
				alert('An unexpected error has occured')
			})
		}).catch((err) => {
			this.setState({ loading: false, selectedFile: null })
			alert('An unexpected error has occured')
		})
	}

	hidePopup() {
		this.setState({
			showPopup: false
		})
	}

	handlePlaylistChange = selectedOption => {
		this.setState(
			{ selectedPlaylist: selectedOption },
			() => console.log(`Option selected:`, selectedOption)
		)
	}

	addPlaylist = e => {
		e.preventDefault()
		if(!this.state.selectedPlaylist)
			return toast.error('Please select a playlist')

		// add playlist into array
		let collection = JSON.parse(JSON.stringify(this.state.collection))
		if(!collection.playlists)
			collection.playlists = []

		collection.playlists.push(this.state.selectedPlaylist)
		collection.playlists = collection.playlists.map(playlist => playlist.id)
		this.setState({
			showPopup: false,
			loading: true
		})
		API.update('collections', this.state.collection.id, collection).then(collection => {
			this.setState({
				selectedPlaylist: null,
				collection: collection,
				playlists: { ...this.state.playlists, entities: collection.playlists },
				loading: false
			})
			toast.success('Collection updated')
		})
	}

	removePlaylist() {

	}

	onChangeCoverFile(event) {
		event.stopPropagation();
		event.preventDefault();

		// Check if there are files to upload
		if(event.target.files.length < 1)
			return

		// @todo, check all files extentions

		// Display loader
		this.setState({ loading: true })

		API.postCollectionCover(event.target.files[0]).then(file => {
			let collection = JSON.parse(JSON.stringify(this.state.collection))
			if(!collection.playlists)
				collection.playlists = []
			collection.cover = file.cover
			collection.coverHash = file.coverHash
			collection.playlists = collection.playlists.map(playlist => playlist.id)
			this.setState({
				showPopup: false,
				loading: true
			})
			API.update('collections', this.state.collection.id, collection).then(collection => {
				this.setState({
					selectedPlaylist: null,
					collection: collection,
					playlists: { ...this.state.playlists, entities: collection.playlists },
					loading: false
				})
				toast.success('Collection updated')
			})
		})
	}

	playlistCover() {
		return <div className="playlist-cover" style={{backgroundImage: 'url(' + API.apiURL + '/storage/collection-cover/' + (this.state.collection.cover && this.state.collection.cover.uri) + ')'}} onClick={(e) => { this.uploadCover.click() }}></div>
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={this.state.collection.name} beforeTitle={this.playlistCover.bind(this)} breadcrumb={this.state.breadcrumb} edit={() => this.props.history.push('/assets/collections/' + this.props.match.params.collectionId + '/edit')} />
				<Content> 
					{
						 this.state.loading === true ? <Loader /> : <>
							<input
								type="file"
								ref={(ref) => this.uploadCover = ref}
								style={{display: 'none'}}
								onChange={this.onChangeCoverFile.bind(this)}
				 				value={this.state.selectedCoverFile}
								accept=".jpg,.png,.jpeg"
							/>
						 	<Table settings={this.state.playlists} />
							<Rodal visible={this.state.showPopup} onClose={this.hidePopup.bind(this)} width={790} height={574}>
								<div className="popup-inner">
									<div className="header"></div>
									<h2>Select a playlist</h2>
									<Select
										value={this.state.selectedPlaylist}
										onChange={this.handlePlaylistChange}
										options={this.state.playlistsList}
										getOptionLabel={playlist => playlist.name}
										getOptionValue={playlist => playlist.id}
									/>
									<div className="actions">
										<button className="blue" onClick={this.addPlaylist.bind(this)}>Save</button>
										<button className="transparent" onClick={this.hidePopup.bind(this)}>Cancel</button>				
									</div>
								</div>
							</Rodal>
						 </>
					}
				</Content>
			</>
	}
}

export default CollectionView;
