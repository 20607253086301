import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import { debounce } from 'lodash'
import Helpers from '../../services/helpers.jsx'
import { toast } from 'react-toastify'

class CollectionList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
			filters: {
				name: ''
			},
			sort: ['updatedAt', 'DESC'],
			tabs: {
				links: [
					{ title: 'Playlists', path: '/assets/playlists' },
					{ title: 'Collections', path: '/assets/collections' }
				]
			},
			collections: {
				columns: [{
					label: 'Collection name',
					key: 'name',
					canSort: true,
					onSortClick: column => {
						if (this.state.sort === []) {
							this.setState({
								sort: ['name', 'DESC']
							})
						} else if (this.state.sort[0] !== 'name') {
							this.setState({
								sort: ['name', 'ASC']
							})
						} else if (this.state.sort[0] === 'name' && this.state.sort[1] === 'ASC') {
							this.setState({
								sort: ['name', 'DESC']
							})
						} else if (this.state.sort[0] === 'name' && this.state.sort[1] === 'DESC') {
							this.setState({
								sort: ['name', 'ASC']
							})
						}

						this.loadEntities()
					}
				}, {
					label: 'Playlists',
					key: 'playlists',
					view: (field) => {
						if(!field)
							return '-'
						return field.length
					}
				}, {
					label: 'Created',
					key: 'createdAt',
					view: function(field) {
						return moment(field).format('l')
					},
					canSort: true,
					onSortClick: column => {
						if (this.state.sort === []) {
							this.setState({
								sort: ['createdAt', 'DESC']
							})
						} else if (this.state.sort[0] !== 'createdAt') {
							this.setState({
								sort: ['createdAt', 'ASC']
							})
						} else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'ASC') {
							this.setState({
								sort: ['createdAt', 'DESC']
							})
						} else if (this.state.sort[0] === 'createdAt' && this.state.sort[1] === 'DESC') {
							this.setState({
								sort: ['createdAt', 'ASC']
							})
						}

						this.loadEntities()
					}
				}, {
					label: 'Updated',
					key: 'updatedAt',
					view: function(field) {
						return moment(field).format('l')
					},
					canSort: true,
					onSortClick: column => {
						if (this.state.sort === []) {
							this.setState({
								sort: ['updatedAt', 'DESC']
							})
						} else if (this.state.sort[0] !== 'updatedAt') {
							this.setState({
								sort: ['updatedAt', 'ASC']
							})
						} else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'ASC') {
							this.setState({
								sort: ['updatedAt', 'DESC']
							})
						} else if (this.state.sort[0] === 'updatedAt' && this.state.sort[1] === 'DESC') {
							this.setState({
								sort: ['updatedAt', 'ASC']
							})
						}

						this.loadEntities()
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this collection?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('collections', id).then(() => {
							toast.success('Collection was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.success('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [{
					label: 'Search for a collection',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}],
				actions: [{
					classList: 'blue add',
					label: 'New collection',
					onClick: () => this.props.history.push('/assets/collections/new')
				}],
				selectedItems: [],
				multiSelect: false,
				onRowClick: (entity) => {
					this.props.history.push('/assets/collections/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getCollections(this.state.filters, this.state.sort).then(entities => this.setState({ ...this.state, collections: {...this.state.collections, entities: entities}, loading: false }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Collections'} tabs={this.state.tabs} />
				<Content>
					<Table settings={this.state.collections} />
				</Content>
			</>
	}
}

export default CollectionList;
