import API from './api.jsx'
import DB from './db.jsx'

class User {
	constructor() {
		this.user = DB.get('user') || null
		let token = DB.get('token') || null
		let expireAt = DB.get('expireAt') || null
		let status = DB.get('status') || null
		if(token)
			API.setToken(token)

		if (status == 'login' && Date.parse(new Date(expireAt)) < Date.parse(new Date()) / 1000) {
			console.log('expired')
			DB.clear('status')
			window.location.href='/login'
		}

		// Get user from local DB
		console.log('USER', DB.get('user'))
		console.log('expireAt', DB.get('expireAt'))
		console.log('status', DB.get('status'))
		console.log(Date.parse(new Date()) / 1000)
	}

	// Login
	login(params) {
		let that = this
		return API.request('post', 'auth/login', params).then(function(res){
			API.setToken(res.token)
			DB.set('user', res.user)
			DB.set('token', res.token)
			DB.set('status', 'login')
			let expireTimestamp = Date.parse(new Date(res.expireAt)) / 1000
			DB.set('expireAt', expireTimestamp)
			that.user = res.user
		})
	}

	// Logout
	logout() {
		this.user = null
		DB.clear('user')
		DB.clear('token')
	}
	
	// Get current logged in user
	current() {
		return this.user
	}

	// User CRUD
	list() {}
	get(id) {}
	create(fields) {}
	update(id, fields) {}
	delete(id) {}
}

export default new User()