import React from 'react';
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Table from '../../layout/table.jsx'
import Helpers from '../../../services/helpers.jsx'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class DeviceList extends React.Component {
	constructor(props) {
		super(props)
		let that = this

		this.state = {
			loading: true,
			filters: {
				client: this.props.match.params.clientId,
				name: '',
				sku: ''
			},
			sort: [],
			devices: {
				columns: [
				{
					label: 'Device name',
					key: 'name',
					canSort: true,
					onSortClick: column => {
						if (this.state.sort === []) {
							this.setState({
								sort: ['name', 'DESC']
							})
						} else if (this.state.sort[0] !== 'name') {
							this.setState({
								sort: ['name', 'ASC']
							})
						} else if (this.state.sort[0] === 'name' && this.state.sort[1] === 'ASC') {
							this.setState({
								sort: ['name', 'DESC']
							})
						} else if (this.state.sort[0] === 'name' && this.state.sort[1] === 'DESC') {
							this.setState({
								sort: ['name', 'ASC']
							})
						}

						this.loadEntities()
					}
				}, 
				{
					label: 'SKU',
					key: 'sku',
					canSort: true,
					onSortClick: column => {
						if (this.state.sort === []) {
							this.setState({
								sort: ['sku', 'DESC']
							})
						} else if (this.state.sort[0] !== 'sku') {
							this.setState({
								sort: ['sku', 'ASC']
							})
						} else if (this.state.sort[0] === 'sku' && this.state.sort[1] === 'ASC') {
							this.setState({
								sort: ['sku', 'DESC']
							})
						} else if (this.state.sort[0] === 'sku' && this.state.sort[1] === 'DESC') {
							this.setState({
								sort: ['sku', 'ASC']
							})
						}

						this.loadEntities()
					}
				}, {
					label: 'Status',
					key: 'lastPing',
					view: function(field) {
						if(!field)
							return <div className="red">offline</div>

						let timestamp = + new Date(field)
						let now = + new Date()
						return now - timestamp > 60 * 60 * 1000 ? <div className="red">offline</div> : <div className="green">online</div>
					}
				}, {
					label: 'Storage left',
					key: 'storage',
					view: function(field){
						if(!field)
							return '-'
						return Helpers.formatBytes(field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)
					}
				}, {
					label: 'Playing',
					key: 'playing'
				}, {
					label: 'Timezone',
					key: 'timezone'
				}, {
					label: 'Software version',
					key: 'softwareVersion',
						canSort: true,
						onSortClick: column => {
							if (this.state.sort === []) {
								this.setState({
									sort: ['softwareVersion', 'DESC']
								})
							} else if (this.state.sort[0] !== 'softwareVersion') {
								this.setState({
									sort: ['softwareVersion', 'ASC']
								})
							} else if (this.state.sort[0] === 'softwareVersion' && this.state.sort[1] === 'ASC') {
								this.setState({
									sort: ['softwareVersion', 'DESC']
								})
							} else if (this.state.sort[0] === 'softwareVersion' && this.state.sort[1] === 'DESC') {
								this.setState({
									sort: ['softwareVersion', 'ASC']
								})
							}

							this.loadEntities()
						}
				}, {
					label: 'Download status',
					key: 'downloadStatus',
					view: function(field) {
						if(!field)
							return '-'
						else if(field.downloaded === field.total)
							return <div className="progress green"><i className="icon-done"></i> Downloaded</div>
						else
							return <div className="progress orange"><i className="icon-downloading"></i> {Math.round(field.downloaded / field.total * 100)}% ({field.downloaded}/{field.total} tracks)</div>

					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to delete this device?");
						if (r === false) return

						this.setState({ loading: true })
						API.delete('devices', id).then(() => {
							toast.success('Device was successfully deleted')
							this.loadEntities()
						}).catch(() => {
							toast.error('An unexpected error has occured')
							this.setState({ loading: false })
						})
					}
				}],
				entities: [],
				filters: [
				{
					label: 'Device name',
					onChange: debounce(value => {
						if(value !== this.state.filters.name)
							this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				},
				{
					label: 'SKU',
					onChange: debounce(value => {
						if(value !== this.state.filters.sku)
							this.setState({ filters: { ...this.state.filters, sku: value ? '/' + value + '/i' : '' }}, () => {
								this.loadEntities()
							})
					}, 500)
				}],
				actions: [{
					classList: 'blue add',
					label: 'New device',
					onClick: () => this.props.history.push('/clients/' + this.props.match.params.clientId + '/devices/new')
				}],
				selectedItems: [],
				multiSelect: false,
				onRowClick: function(entity) {
					that.props.history.push('/clients/' + props.match.params.clientId + '/devices/' + entity.id)
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getDevices(this.state.filters, this.state.sort).then(entities => this.setState({ ...this.state, loading: false, devices: {...this.state.devices, entities: entities} }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Table settings={this.state.devices} />
			</>
	}
}

export default DeviceList;
