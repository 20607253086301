import React from 'react';
import API from '../../services/api.jsx'
import Helpers from '../../services/helpers.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import CampaignForm from './form.jsx'
import { toast } from 'react-toastify'
import moment from 'moment'
import { setDatesToMidnight } from '../../services/campaign.js';

class CampaignsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
			breadcrumb: { title: 'All campaigns', path: '/campaigns' },
			devices: {
				columns: [
				{
					label: 'Device name',
					key: 'name'
				},
				{
					label: 'SKU',
					key: 'sku'
				}, {
					label: 'Status',
					key: 'lastPing',
					view: function(field) {
						if(!field)
							return <div className="red">offline</div>

						let timestamp = + new Date(field)
						let now = + new Date()
						return now - timestamp > 60 * 60 * 1000 ? <div className="red">offline</div> : <div className="green">online</div>
					}
				}, {
					label: 'Storage left',
					key: 'storage',
					view: function(field){
						if(!field)
							return '-'
						return Helpers.formatBytes(field.externalAvailable) + '/' + Helpers.formatBytes(field.externalTotal)
					}
				}, {
					label: 'Playing',
					key: 'playing'
				}, {
					label: 'Timezone',
					key: 'timezone'
				}, {
					label: 'Software version',
					key: 'softwareVersion'
				}, {
					label: 'Download status',
					key: 'downloadStatus',
					view: function(field) {
						if(!field)
							return '-'
						else if(field.downloaded === field.total)
							return <div className="progress green"><i className="icon-done"></i> Downloaded</div>
						else
							return <div className="progress orange"><i className="icon-downloading"></i> {Math.round(field.downloaded/field.total)}% ({field.downloaded}/{field.total} tracks)</div>
					}
				}],
				entities: [],
				filters: [],
				actions: [],
				selectedItems: [],
				multiSelect: true
			},
			voiceOvers: {
				columns: [],
				entities: [],
				filters: [],
				actions: [{
					classList: 'blue add',
					label: 'Add voice over',
					onClick: (e) => {
						console.log(this.state.devices.selectedItems)
						e.preventDefault()
					}
				}],
				selectedItems: [],
				multiSelect: false
			},

			// Live playlists table
			livePlaylists: {
				columns: [{
					label: 'Playlist name',
					key: 'name'
				}, {
					label: 'Hidden in manual',
					key: 'hidden',
					view: function (field) {
						if (!field || field == false) {
							return 'False'
						} else {
							return 'True'
						}
					}
				}],
				entities: [],
				filters: [],
				actions: [],
				selectedItems: [],
				multiSelect: false
			},

			// Manual playlists table
			manualPlaylists: {
				columns: [{
					label: 'Playlist name',
					key: 'name'
				}, {
					label: 'Tracks',
					key: 'assets',
					view: function(field) {
						if(!field)
							return '-'
						return field.length
					}
				}, {
					label: 'Duration',
					key: 'duration',
					view: function(field) {
						if(!field)
							return '-'

						return Helpers.formatTime(field)
					}
				}, {
					label: 'Vox tag',
					key: 'tag'
				}, {
					label: 'Type',
					key: 'type',
					view: field => {
						if(field === 'vox')
							return 'VOX Playlist'
						else if(field === 'custom')
							return 'Tailor-made'
						else if(field === 'sr_library')
							return 'SR Library'
						else
							return '-'
					}
				}, {
					label: 'Created',
					key: 'createdAt',
					view: function(field) {
						return moment(field).format('l')
					}
				}, {
					label: 'Updated',
					key: 'updatedAt',
					view: function(field) {
						return moment(field).format('l')
					}
				}, {
					action: 'delete',
					onClick: (id, key) => {
						var r = window.confirm("Are you sure you want to remove this playlist?");
						if (r === false) return

						let playlists = [...this.state.manualPlaylists.entities]
						playlists.splice(key, 1)
						this.setState({
							...this.state,
							manualPlaylists: { ...this.state.manualPlaylists, entities: playlists }
						})
					}
				}],
				entities: [],
				filters: [],
				actions: [{
					classList: 'blue add',
					label: 'Add manual playlist',
					onClick: (e) => {}
				}],
				selectedItems: [],
				multiSelect: false
			},
			campaign: null,
			playlists: [],
			collections: []
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		Promise.all([
			API.getCampaign(this.props.match.params.campaignId).then(campaign => this.setState({ campaign: campaign })),
			API.getPlaylists().then(playlists => this.setState({ playlists: playlists })),
			API.getCollections().then(collections => this.setState({ collections: collections }))
		]).then(() => {
			setTimeout(() => {
				API.getDevices({ client: this.state.campaign.client }).then(entities => {
					// Get manual playlists
					let playlistsIndex = this.state.playlists.map(p => p.id)
					if(this.state.campaign && this.state.campaign.manualPlaylists) {
						// this.state.manualPlaylists.entities = this.state.campaign.manualPlaylists.map(id => this.state.playlists[playlistsIndex.indexOf(id)])

						var manualPlaylists = []
						this.state.campaign.manualPlaylists.map(id => {
							let manualPlaylist = {}
							manualPlaylist = this.state.playlists[playlistsIndex.indexOf(id)]
							this.state.campaign.manualPlaylistSettings.map(manualPlaylistSetting => {
								if (manualPlaylistSetting.playlist == id) {
									manualPlaylist.name = manualPlaylistSetting.name
									// manualPlaylist.hidden = manualPlaylistSetting.hidden
								}
							})
							manualPlaylists.push(manualPlaylist)
						})
						this.state.manualPlaylists.entities = manualPlaylists
					}

					// Get live playlists
					if(this.state.campaign.schedules) {
						let newLivePlaylists = []
						this.state.campaign.schedules.map(schedule => {
							if(schedule.playlist) {
								this.state.playlists.map(playlists => {
									if (playlists.id == schedule.playlist) {
										newLivePlaylists.push(playlists)
									}
								})
							}

							if(schedule.playlistCollection) {
								this.state.collections.map(collection => {
									if (collection.id == schedule.playlistCollection) {
										newLivePlaylists = newLivePlaylists.concat(collection.playlists)
									}
								})
							}
						})

						// Remove duplicate object
						let tmpLivePlaylists = []
						let obj = {}
						newLivePlaylists.map(newLivePlaylist => {
							if (!obj[newLivePlaylist.id]) {
								// tmpLivePlaylists.push(newLivePlaylist)
								// obj[newLivePlaylist.id] = true

								let livePlaylist = {}
								let found = false
								this.state.campaign.manualPlaylistSettings.map(manualPlaylistSetting => {
									if (manualPlaylistSetting.playlist == newLivePlaylist.id) {
										found = true
										livePlaylist.name = manualPlaylistSetting.name
										livePlaylist.hidden = manualPlaylistSetting.hidden
										livePlaylist.playlist = manualPlaylistSetting.playlist
									}
								})
								if(found == false) {
									livePlaylist.name = newLivePlaylist.name
									livePlaylist.playlist = newLivePlaylist.id
								}
								tmpLivePlaylists.push(livePlaylist)
								obj[newLivePlaylist.id] = true
							}
						})

						this.state.livePlaylists.entities = tmpLivePlaylists
					}

					this.setState({ ...this.state, devices: {...this.state.devices, entities: entities} }, () => {
						let selectedDevices = []
						this.state.devices.entities && this.state.devices.entities.forEach(device => {
							if(!this.state.campaign.devices)
								selectedDevices.push(false)

							else if(this.state.campaign.devices.indexOf(device.id) > -1)
								selectedDevices.push(true)

							else
								selectedDevices.push(false)
						})

						this.setState({ devices: { ...this.state.devices, selectedItems: selectedDevices }, loading: false })
					})
				})

			}, 100)
		})
	}

	saveEntity(fields, schedules) {
		this.setState({ loading: true })

		// map devices
		fields.devices = []
		this.state.devices.selectedItems.forEach((value, key) => {
			// console.log(this.state.devices, key)
			if(value)
				fields.devices.push(this.state.devices.entities[key].id)
		})

		if(fields.devices.length < 1) {
			var r = window.confirm("There is no devices associated with this campaign, are you sure you want to save it?");
			if (r === false) return
		}

		// Fill manual playlists
		fields.manualPlaylists = this.state.manualPlaylists.entities.map(e => e.id)

		setDatesToMidnight(fields)

		API.update('campaigns', this.props.match.params.campaignId, fields).then((campaign) => {
			this.saveSchedules(schedules, campaign.id).then(() => {
				toast.success('Campaign has been updated')
				this.props.history.push('/campaigns')
			})
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	saveSchedules(schedules, campaignId) {
		return new Promise((resolve, reject) => {
			if(!schedules || !schedules.length)
				return resolve()

			let operations = []
			schedules.forEach(schedule => {
				if(schedule.playlist)
					schedule.playlist = schedule.playlist.id
				if(schedule.playlistCollection)
					schedule.playlistCollection = schedule.playlistCollection.id
				schedule.campaign = campaignId

				if(!schedule.id && !schedule.deleted)
					operations.push(API.create('schedules', schedule))

				if(schedule.id && !schedule.deleted)
					operations.push(API.update('schedules', schedule.id, schedule))

				if(schedule.id && schedule.deleted)
					operations.push(API.delete('schedules', schedule.id))
			})

			Promise.all(operations).then(resolve)
		});
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Edit campaign'} breadcrumb={this.state.breadcrumb} tabs={this.state.tabs} />
				<Content>
					<CampaignForm
						entity={this.state.campaign}
						devices={this.state.devices}
						collections={this.state.collections}
						playlists={this.state.playlists}
						parent={this}
						livePlaylists={this.state.livePlaylists}
						manualPlaylists={this.state.manualPlaylists}
						voiceOvers={this.state.voiceOvers}
						cancel={() => this.props.history.push('/campaigns')}
						save={this.saveEntity}
					/>
				</Content>
			</>
	}
}

export default CampaignsList;
